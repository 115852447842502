/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 1,
    name: 'Altcoin',
    slug: 'altcoin',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3908,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 2,
    name: 'AUD',
    slug: 'aud',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3968,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 3,
    name: 'Bên lề Trading',
    slug: 'ben-le-trading',
    groupName: 'COLUMN',
    wp_id: 3975,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 4,
    name: 'Scalping',
    slug: 'scalping',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3955,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 5,
    name: 'Bitcoin - BTC',
    slug: 'bitcoin',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 9,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 6,
    name: 'Banker_sharing',
    slug: 'banker-sharing',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3695,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 8,
    name: 'Back test',
    slug: 'back-test',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3945,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 9,
    name: 'Scale-in - Scale-out',
    slug: 'scale-in-scale-out',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3943,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 10,
    name: 'CAD',
    slug: 'cad',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3974,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 11,
    name: 'Cách đào bitcoin',
    slug: 'cach-dao-bitcoin',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3916,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 12,
    name: 'Biểu đồ - Charting',
    slug: 'bieu-do-charting',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3942,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 13,
    name: 'Chỉ báo (Indicators)',
    slug: 'chi-bao-indicators',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3960,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 14,
    name: 'Chỉ báo biến động và điểm ra vào vị thế',
    slug: 'chi-bao-bien-dong-va-diem-ra-vao-vi-the',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3964,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 15,
    name: 'Chỉ báo - Indicators',
    slug: 'chi-bao_general',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3961,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 16,
    name: 'Các yếu tố ảnh hưởng đến giá vàng',
    slug: 'cac-yeu-to-anh-huong-den-gia-vang',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3926,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 17,
    name: 'AUD/USD',
    slug: 'aud-usd',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3750,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 18,
    name: 'Chiến lược giao dịch vàng',
    slug: 'chien-luoc-giao-dich-vang',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3931,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 19,
    name: 'Đầu tư tài chính',
    slug: 'dau-tu',
    groupName: 'FIELD',
    wp_id: 1572,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 20,
    name: 'Công cụ hỗ trợ giao dịch vàng',
    slug: 'cong-cu-ho-tro-giao-dich-vang',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3932,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 21,
    name: 'Câu chuyện trader',
    slug: 'cau-chuyen-trader',
    groupName: 'COLUMN',
    wp_id: 3965,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 22,
    name: 'Chỉ báo động lượng',
    slug: 'chi-bao-dong-luong',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3963,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 23,
    name: 'Cơ bản về vàng và thị trường vàng',
    slug: 'thi-truong-vang',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3919,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 24,
    name: 'Coin',
    slug: 'coins',
    groupName: 'FIELD',
    wp_id: 3733,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 25,
    name: 'Chỉ báo xu hướng',
    slug: 'chi-bao-xu-huong',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3962,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 27,
    name: 'Kiến thức chứng khoán',
    slug: 'kien-thuc-chung-khoan',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3745,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 28,
    name: 'EUR/USD',
    slug: 'eur-usd',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3747,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 29,
    name: 'GBP/USD',
    slug: 'gbp-usd',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3748,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 30,
    name: 'EA-Robot Forex',
    slug: 'ea-robot-forex',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3948,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 31,
    name: 'Kiến thức chung khác',
    slug: 'kien-thuc-chung-khac',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3904,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 32,
    name: 'Quản lý giao dịch của Pro trader',
    slug: 'quan-ly-giao-dich-cua-pro-trader',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3954,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 33,
    name: 'Phân tích &amp; nhận định',
    slug: 'phan-tich-nhan-dinh',
    groupName: 'COLUMN',
    wp_id: 3739,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 34,
    name: 'Tether - USDT',
    slug: 'tether',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 12,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 35,
    name: 'Phân tích cơ bản',
    slug: 'phan-tich-co-ban',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3902,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 36,
    name: 'Tin Forex',
    slug: 'tin-forex',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 1,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 37,
    name: 'Tương quan giữa vàng và các kênh đầu tư khác',
    slug: 'vang-va-cac-kenh-dau-tu-khac',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3928,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 38,
    name: 'WTI',
    slug: 'wti',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3972,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 39,
    name: 'Vàng - XAUUSD',
    slug: 'xau-usd',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3753,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 40,
    name: 'USD/CAD',
    slug: 'usd-cad',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3751,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 41,
    name: 'USD/CHF',
    slug: 'usd-chf',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3911,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 42,
    name: 'Kiến thức đầu tư tài chính',
    slug: 'kien-thuc-dau-tu-tai-chinh',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3746,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 43,
    name: 'Thực chiến nghề Trading',
    slug: 'thuc-chien-nghe-trading',
    groupName: 'COLUMN',
    wp_id: 3976,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 44,
    name: 'Tín hiệu giao dịch Forex',
    slug: 'tin-hieu-giao-dich-forex',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 6,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 45,
    name: 'Trade quỹ',
    slug: 'trade-quy',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3991,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 46,
    name: 'Hàng hóa',
    slug: 'hang-hoa',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3755,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 47,
    name: 'Chứng khoán',
    slug: 'chung-khoan',
    groupName: 'FIELD',
    wp_id: 3734,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 48,
    name: 'Chỉ báo khối lượng',
    slug: 'chi-bao-khoi-luong',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3966,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 49,
    name: 'Stoploss và Take Profit',
    slug: 'stoploss-va-take-profit',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3938,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 50,
    name: 'Tin tức chứng khoán',
    slug: 'tin-tuc-chung-khoan',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3741,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 51,
    name: 'CHF',
    slug: 'chf',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3973,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 52,
    name: 'Phân tích kỹ thuật',
    slug: 'phan-tich-ky-thuat',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3901,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 53,
    name: 'Khuyến mãi Forex Broker',
    slug: 'khuyen-mai-forex-broker',
    groupName: 'COLUMN',
    wp_id: 3,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 54,
    name: 'Điều kiện thị trường',
    slug: 'dieu-kien-thi-truong',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3940,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 55,
    name: 'Kiến thức tiền điện tử',
    slug: 'kien-thuc-tien-dien-tu',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3744,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 56,
    name: 'Ethereum - ETH',
    slug: 'ethereum',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 11,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 57,
    name: 'EUR',
    slug: 'eur',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3971,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 58,
    name: 'Kiến thức',
    slug: 'kien-thuc',
    groupName: 'COLUMN',
    wp_id: 3737,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 59,
    name: 'Quản lý vốn',
    slug: 'quan-ly-von',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3903,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 60,
    name: 'Đầu tư vàng vào đâu?',
    slug: 'cong-cu-dau-tu-vang',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3918,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 61,
    name: 'Đầu tư vàng vào đâu?',
    slug: 'dau-tu-vang-vao-dau',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3925,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 62,
    name: 'Kinh nghiệm trading',
    slug: 'kinh-nghiem-trading',
    groupName: 'COLUMN',
    wp_id: 3986,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 63,
    name: 'Tin tức tiền điện tử',
    slug: 'tin-tuc-tien-dien-tu',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3740,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 65,
    name: 'Thông báo từ Broker',
    slug: 'thong-bao-tu-broker',
    groupName: 'COLUMN',
    wp_id: 4,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 66,
    name: 'GBP',
    slug: 'gbp',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3970,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 67,
    name: 'Xu hướng defi',
    slug: 'xu-huong-defi',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3915,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 68,
    name: 'Position trading',
    slug: 'position-trading',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3957,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 69,
    name: 'Sàn giao dịch Forex',
    slug: 'san-giao-dich-forex',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3934,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 70,
    name: 'Sàn và giao dịch trên sàn',
    slug: 'san-va-giao-dich-tren-san',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3905,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 71,
    name: 'Nhập môn đầu tư forex',
    slug: 'nhap-mon-dau-tu-forex',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3933,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 72,
    name: 'Forex',
    slug: 'forex',
    groupName: 'FIELD',
    wp_id: 1445,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 73,
    name: 'Công cụ giao dịch',
    slug: 'cong-cu-giao-dich',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3906,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 74,
    name: 'Price Action',
    slug: 'price-action',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3947,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 75,
    name: 'Platform giao dịch',
    slug: 'platform-giao-dich',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3910,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 76,
    name: 'Kiến thức forex',
    slug: 'kien-thuc-forex',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3743,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 77,
    name: 'JPY',
    slug: 'jpy',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3969,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 78,
    name: 'Swing trading',
    slug: 'swing-trading',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3958,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 79,
    name: 'Tín hiệu',
    slug: 'tin-hieu',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3977,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 80,
    name: 'Plan tuần',
    slug: 'plan-tuan',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3978,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 81,
    name: 'USD',
    slug: 'usd',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3967,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 82,
    name: 'Thesis',
    slug: 'thesis',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3959,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 83,
    name: 'Ripple - XRP',
    slug: 'ripple',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 13,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 84,
    name: 'Phong cách giao dịch',
    slug: 'phong-cach-giao-dich',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3953,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 85,
    name: 'Nến Nhật',
    slug: 'nen-nhat',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3949,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 86,
    name: 'USD index',
    slug: 'us-dollar-index',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3756,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 87,
    name: 'Volume',
    slug: 'volume',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3939,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 88,
    name: 'Review lệnh',
    slug: 'review-lenh',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3979,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 89,
    name: 'Review Broker',
    slug: 'review',
    groupName: 'COLUMN',
    wp_id: 3391,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 90,
    name: 'Kinh nghiệm thực chiến',
    slug: 'kinh-nghiem-thuc-chien',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3988,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 91,
    name: 'USD/JPY',
    slug: 'usd-jpy',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3749,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 92,
    name: 'Key level',
    slug: 'key-level',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3941,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 93,
    name: 'Phong cách trade',
    slug: 'phong-cach-trade',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3907,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 94,
    name: 'Tin tức',
    slug: 'tin-tuc',
    groupName: 'COLUMN',
    wp_id: 3736,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 95,
    name: 'Review cổ phiếu',
    slug: 'review-co-phieu',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3909,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 96,
    name: 'Phân tích &amp; nhận định chứng khoán',
    slug: 'phan-tich-nhan-dinh-chung-khoan',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3754,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 97,
    name: 'Dầu',
    slug: 'dau-tho',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3752,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 98,
    name: 'Chuyên đề đặc biệt',
    slug: 'special-topic',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3693,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 99,
    name: 'Mẫu hình giá',
    slug: 'mau-hinh-gia',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3950,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 100,
    name: 'Litecoin - LTC',
    slug: 'litecoin',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 10,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 101,
    name: 'Nhận định',
    slug: 'nhan-dinh',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3984,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 102,
    name: 'Cơ hội đầu tư',
    slug: 'co-hoi-dau-tu',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3923,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 103,
    name: 'Tâm lý giao dịch',
    slug: 'tam-ly-giao-dich',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3951,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 104,
    name: 'Tin tức đầu tư tài chính',
    slug: 'tin-tuc-dau-tu-tai-chinh',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3742,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 105,
    name: 'Day trading',
    slug: 'day-trading',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3956,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 106,
    name: 'Kinh nghiệm giao dịch Forex',
    slug: 'kinh-nghiem-giao-dich-forex',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 5,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 107,
    name: 'Cuộc sống trading',
    slug: 'cuoc-song-trading',
    groupName: 'COLUMN',
    wp_id: 3987,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 110,
    name: 'Cách làm giàu',
    slug: 'cach-lam-giau',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 4006,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 115,
    name: 'Nông sản',
    slug: 'nong-san',
    groupName: 'COLUMN',
    wp_id: 4010,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 121,
    name: 'Chứng chỉ quỹ',
    slug: 'chung-chi-quy',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 4002,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 124,
    name: 'Các đồng coin trên thế giới',
    slug: 'cac-dong-coin-tren-the-gioi',
    groupName: 'COLUMN',
    wp_id: 3998,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 132,
    name: 'Năng lượng',
    slug: 'nang-luong',
    groupName: 'COLUMN',
    wp_id: 4008,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 136,
    name: 'Key',
    slug: 'key',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3944,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 140,
    name: 'Giá vàng hôm nay',
    slug: 'gia-vang-hom-nay',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3997,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 146,
    name: 'Tỷ giá bảng Anh',
    slug: 'ty-gia-bang-anh',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3995,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 147,
    name: 'Đầu tư vàng',
    slug: 'dau-tu-vang',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 4004,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 155,
    name: 'Cổ phiếu',
    slug: 'co-phieu',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 4000,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 157,
    name: 'Đầu tư bất động sản',
    slug: 'dau-tu-bat-dong-san',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 4005,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 158,
    name: 'Tỷ giá đô la Úc',
    slug: 'ty-gia-dola-uc',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3994,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 161,
    name: 'Phân tích - nhận định thị trường',
    slug: 'phan-tich-nhan-dinh-thi-truong',
    groupName: 'COLUMN',
    wp_id: 4011,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 163,
    name: 'Tài chính cá nhân',
    slug: 'tai-chinh-ca-nhan',
    groupName: 'COLUMN',
    wp_id: 3999,
  },
  {
    lastModifiedDate: '2024-10-05T07:45:36.934Z',
    id: 167,
    name: 'Tỷ giá Euro',
    slug: 'ty-gia-euro',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3993,
  },
  {
    lastModifiedDate: '2024-10-07T14:27:50.702Z',
    id: 168,
    name: 'Nguyên liệu công nghiệp',
    slug: 'nguyen-lieu-cong-nghiep',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 4009,
  },
  {
    lastModifiedDate: '2024-10-07T14:49:08.285Z',
    id: 169,
    name: 'Vàng và kim loại',
    slug: 'vang-va-kim-loai',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 4007,
  },
  {
    lastModifiedDate: '2024-10-07T14:49:08.285Z',
    id: 170,
    name: 'Phái sinh',
    slug: 'phai-sinh',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 4003,
  },
  {
    lastModifiedDate: '2024-10-07T14:49:08.285Z',
    id: 171,
    name: 'Trái phiếu',
    slug: 'trai-phieu',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 4001,
  },
  {
    lastModifiedDate: '2024-10-07T14:49:08.285Z',
    id: 172,
    name: 'Tỷ giá Yên Nhật',
    slug: 'ty-gia-yen-nhat',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3996,
  },
  {
    lastModifiedDate: '2024-10-07T14:49:08.285Z',
    id: 173,
    name: 'Tỷ giá USD',
    slug: 'ty-gia-usd',
    groupName: 'PRODUCT_TOPIC_BROKER',
    wp_id: 3992,
  },
];
